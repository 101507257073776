<template>
  <b-container fluid>
    <b-row style="gap: 20px 0" class="mb-4 d-flex align-items-stretch">
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-start"
          v-model="filters.start"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="FROM"
        ></b-form-datepicker>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-end"
          v-model="filters.end"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="TO"
        ></b-form-datepicker>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        md="6"
        style="gap: 32px"
        class="mb-2 d-flex align-items-center"
      >
        <b-button
          :class="{ 'btn-selectedRangeGroup ': selectedRange !== 'day' }"
          @click="setDay"
          class="rounded-6px py-2 px-4"
          >這一天</b-button
        >
        <b-button
          :class="{ 'btn-selectedRangeGroup ': selectedRange !== 'week' }"
          @click="setWeek"
          class="rounded-6px py-2 px-4 "
          >這週</b-button
        >
        <b-button
          :class="{ ' btn-selectedRangeGroup ': selectedRange !== 'month' }"
          @click="setMonth"
          class="rounded-6px py-2 px-4"
          >這個月</b-button
        >
        <b-button
          :class="{ ' btn-selectedRangeGroup ': selectedRange !== 'year' }"
          @click="setYear"
          class="rounded-6px py-2 px-4"
          >今年</b-button
        >
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-stretch" style="gap: 20px 0">
      <b-col md="6" xl="3">
        <b-card
          no-body
          class="overflow-hidden shadow-card d-flex p-3 flex-column justify-content-center"
          style="min-height: 148px; width: 100%"
        >
          <div no-gutters class="d-flex align-items-center" style="width: 100%">
            <div
              style="background-color: #edf5ef"
              class="rounded-circle icon-style d-flex flex-column justify-content-center align-items-center"
            >
              <div><IconRevenues /></div>
            </div>
            <div class="pl-4">
              <p>總收入</p>
              <h5 class="text-lg" style="word-break: break-all">
                $HKD {{ statistics.totalRevenue }}
              </h5>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col md="6" xl="3">
        <b-card
          no-body
          class="overflow-hidden shadow-card d-flex p-3 flex-column justify-content-center"
          style="min-height: 148px"
        >
          <div no-gutters class="d-flex align-items-center">
            <div
              style="background-color: #eff7fc"
              class="rounded-circle icon-style d-flex flex-column justify-content-center align-items-center"
            >
              <div><IconTotalOrder /></div>
            </div>
            <div class="pl-4">
              <p>總客戶訂單</p>
              <h5 class="text-lg" style="word-break: break-all">
                {{ statistics.numOfOrders }}
              </h5>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col md="6" xl="3">
        <b-card
          no-body
          class="overflow-hidden shadow-card d-flex p-3 flex-column justify-content-center"
          style="min-height: 148px"
        >
          <div no-gutters class="d-flex align-items-center">
            <div
              style="background-color: #fcf8ec"
              class="rounded-circle icon-style d-flex flex-column justify-content-center align-items-center"
            >
              <div><IconCancelled /></div>
            </div>
            <div class="pl-4">
              <p>總取消訂單</p>
              <h5 class="text-lg" style="word-break: break-all">
                {{ statistics.numOfCancelledOrders }}
              </h5>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col md="6" xl="3">
        <b-card
          no-body
          class="overflow-hidden shadow-card d-flex p-3 flex-column justify-content-center"
          style="min-height: 148px"
        >
          <div no-gutters class="d-flex align-items-center">
            <div
              style="background-color: #edf5ef"
              class="rounded-circle icon-style d-flex flex-column justify-content-center align-items-center"
            >
              <div><IconCompletedOrder /></div>
            </div>
            <div class="pl-4">
              <p>總完成訂單</p>
              <h5 class="text-lg" style="word-break: break-all">
                {{ statistics.numOfCompletedOrders }}
              </h5>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import IconRevenues from '../components/icons/IconRevenues'
import IconTotalOrder from '../components/icons/IconTotalOrder'
import IconCancelled from '../components/icons/IconCancelledOrder'
import IconCompletedOrder from '../components/icons/IconCompletedOrder'
import orderService from '../services/order.service.js'
export default {
  data: () => ({
    statistics: [],
    filters: {
      start: '',
      end: ''
    },
    selectedRange: ''
  }),
  components: {
    IconRevenues,
    IconTotalOrder,
    IconCancelled,
    IconCompletedOrder
  },
  mounted () {
    this.setMonth()
    this.getDashboardStatistics()
  },
  watch: {
    'filters.start': function (newVal) {
      if (new Date(this.filters.end) < new Date(newVal)) {
        this.filters.end = newVal
      }
      this.validateSelectedRange()
      this.getDashboardStatistics()
    },
    'filters.end': function (newVal) {
      if (new Date(newVal) < new Date(this.filters.start)) {
        this.filters.start = newVal
      }
      this.validateSelectedRange()
      this.getDashboardStatistics()
    }
  },
  methods: {
    async getDashboardStatistics () {
      var endDate = new Date(this.filters.end)
      endDate.setDate(endDate.getDate() + 1)
      const formattedEndDate = endDate.toLocaleDateString('en-CA')
      await orderService
        .getDashboardStatistics(this.filters.start, formattedEndDate)
        .then((resData) => {
          this.statistics = resData
        })
    },
    setDay () {
      const today = new Date().toLocaleDateString('en-CA')
      this.filters.start = today
      this.filters.end = today
      this.selectedRange = 'day'
      this.getDashboardStatistics()
    },
    setWeek () {
      const today = new Date()
      const startOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay() + 1)
      ).toLocaleDateString('en-CA')
      const endOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay() + 7)
      ).toLocaleDateString('en-CA')
      this.filters.start = startOfWeek
      this.filters.end = endOfWeek
      this.selectedRange = 'week'
      this.getDashboardStatistics()
    },
    setMonth () {
      const today = new Date()
      const startOfMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      ).toLocaleDateString('en-CA')
      const endOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ).toLocaleDateString('en-CA')
      this.filters.start = startOfMonth
      this.filters.end = endOfMonth
      this.selectedRange = 'month'
      this.getDashboardStatistics()
    },
    setYear () {
      const today = new Date()
      const startOfYear = new Date(
        today.getFullYear(),
        0,
        1
      ).toLocaleDateString('en-CA')
      const endOfYear = new Date(
        today.getFullYear(),
        11,
        31
      ).toLocaleDateString('en-CA')
      this.filters.start = startOfYear
      this.filters.end = endOfYear
      this.selectedRange = 'year'
      this.getDashboardStatistics()
    },
    validateSelectedRange () {
      const start = new Date(this.filters.start).toLocaleDateString('en-CA')
      const end = new Date(this.filters.end).toLocaleDateString('en-CA')
      let isValid = false

      const today = new Date()
      const todayDate = today.toLocaleDateString('en-CA')

      const startOfWeek = new Date(today)
      startOfWeek.setDate(today.getDate() - today.getDay() + 1)
      const endOfWeek = new Date(startOfWeek)
      endOfWeek.setDate(startOfWeek.getDate() + 6)

      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      const startOfYear = new Date(today.getFullYear(), 0, 1)
      const endOfYear = new Date(today.getFullYear(), 11, 31)

      switch (this.selectedRange) {
        case 'day': {
          isValid = start === todayDate && end === todayDate
          break
        }
        case 'week': {
          isValid = start === startOfWeek.toLocaleDateString('en-CA') && end === endOfWeek.toLocaleDateString('en-CA')
          break
        }
        case 'month': {
          isValid = start === startOfMonth.toLocaleDateString('en-CA') && end === endOfMonth.toLocaleDateString('en-CA')
          break
        }
        case 'year': {
          isValid = start === startOfYear.toLocaleDateString('en-CA') && end === endOfYear.toLocaleDateString('en-CA')
          break
        }
        default: {
          // Determine the range based on current date
          if (start === todayDate && end === todayDate) {
            this.selectedRange = 'day'
            isValid = true
          } else if (start === startOfWeek.toLocaleDateString('en-CA') && end === endOfWeek.toLocaleDateString('en-CA')) {
            this.selectedRange = 'week'
            isValid = true
          } else if (start === startOfMonth.toLocaleDateString('en-CA') && end === endOfMonth.toLocaleDateString('en-CA')) {
            this.selectedRange = 'month'
            isValid = true
          } else if (start === startOfYear.toLocaleDateString('en-CA') && end === endOfYear.toLocaleDateString('en-CA')) {
            this.selectedRange = 'year'
            isValid = true
          }
        }
      }

      if (!isValid) {
        this.selectedRange = ''
      }
    }

  }
}
</script>
<style scope>
.icon-style {
  height: 80px;
  width: 80px;
  min-height: 80px;
  min-width: 80px;
}
.btn-selected {
  background-color: #9f836e;
  border: 1px solid #e6d9d1;
  color: white;
  height: 100%;
}
.btn-selectedRangeGroup {
  background-color: white;
  border: 1px solid #e6d9d1;
  color: #9f836e;
  height: 100%;
}
</style>
